import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ProjectHeader from '../../components/Header/ProjectHeader';
import { SimpleGrid } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { col2, xgap, mypx} from '../../constants/spacing';
import SEO from '../../components/SEO';
import ProjectArrows, { ProjectLinkers } from '../../components/Header/ProjectArrows';
const preventClick = (e) => e.preventDefault()
const Parkhouse = ({data, location}) => {
    const dat = data.allWpProject.edges[0].node.hollandProjects
    const DesktopVideo = dat.headerVideoDesktop.localFile.publicURL
    const MobileVideo = dat.headerVideoMobile.localFile.publicURL
  
    let image1;
    let image2;
    let image3;
    let image4;
    let image5;
    let image6;
    let image7;
    let image8;
    let image9;
    let image10;
    let image11;
    let image12;
    
    if(dat.image1) {
      image1 = getImage(dat.image1.localFile)
    } 
    if(dat.image2) {
       image2 = getImage(dat.image2.localFile)
    } 
    if(dat.image3) {
       image3 = getImage(dat.image3.localFile)
    } 
    if(dat.image4) {
       image4 = getImage(dat.image4.localFile)
    } 
    if(dat.image5) {
       image5 = getImage(dat.image5.localFile)
    } 
    if(dat.image6) {
       image6 = getImage(dat.image6.localFile)
    } 
    if(dat.image7) {
       image7 = getImage(dat.image7.localFile)
    } 
    if(dat.image8) {
       image8 = getImage(dat.image8.localFile)
    } 
    if(dat.image9) {
       image9 = getImage(dat.image9.localFile)
    } 
    if(dat.image10) {
       image10 = getImage(dat.image10.localFile)
    } 
    if(dat.image11) {
       image11 = getImage(dat.image11.localFile)
    } 
    if(dat.image12) {
       image12 = getImage(dat.image12.localFile)
    } 
    return (
        <>
        <SEO title={dat.projecttitle} description={dat.projectdescription} image={image1} url="https://hollandhames.com/project/parkhouse"/>
        <ProjectHeader title={dat.projecttitle} description={dat.projectdescription} mobileVid={MobileVideo} desktopVid={DesktopVideo} /> 
        <SimpleGrid columns={1} gap={xgap} px={mypx} pb={xgap}>
        <SimpleGrid columns={col2} gap={xgap}>
            <GatsbyImage onContextMenu={preventClick} image={image1} alt={dat.image1.altText} />
            <GatsbyImage onContextMenu={preventClick} image={image2} alt={dat.image2.altText} />
        </SimpleGrid>
        <SimpleGrid gap={xgap}>
            <GatsbyImage onContextMenu={preventClick} image={image3} alt={dat.image3.altText} />
        </SimpleGrid>
        <SimpleGrid columns={col2} gap={xgap}>
            <GatsbyImage onContextMenu={preventClick} image={image4} alt={dat.image4.altText} />
            <GatsbyImage onContextMenu={preventClick} image={image5} alt={dat.image5.altText} />
        </SimpleGrid>
        <SimpleGrid gap={xgap}>
            <GatsbyImage onContextMenu={preventClick} image={image6} alt={dat.image6.altText} />
        </SimpleGrid>
        </SimpleGrid>
        <ProjectLinkers prevTitle='Le Lapin' prevUrl='/project/le-lapin' nextTitle='More Goodies' nextUrl='/project/more-goodies' />
        </>
    )
}

export default Parkhouse
export const query = graphql`
  {
    allWpProject(filter: {slug: {eq: "park-house"}}) {
      edges {
        node {
          title
          hollandProjects {
            projecttitle
            projectdescription
            projectcategories
            image1 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image2 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image3 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image4 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image5 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image6 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image7 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image8 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image9{
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            image10 {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            headerVideoDesktop {
              localFile {
                publicURL
              }
            }
            headerVideoMobile {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`